import React from "react";
import { Link } from "react-router-dom";
import "./Navigation.css";

export default function Menu({ info, currentLocation }) {
  return (
    <Link
      to={info.path}
      className={`list-group-item list-group-item-action py-2 ripple ${
        currentLocation.pathname === `${info.path}` ? "active" : ""
      }`}
    >
      {info.icon}
      <span>{info.title}</span>
    </Link>
  );
}
